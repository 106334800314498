// Palette color codes */
// Palette URL: http://paletton.com/#uid=13n0u0kpJw0gCuGjxCrvCrfI+DV */

// As hex codes */
$color-primary-0: #23869E;	// Main Primary color */
$color-primary-1: #4B8997;
$color-primary-2: #49A0B4;
$color-primary-3: #056E86;
$color-primary-4: #0396B9;

// As RGBa codes */
$rgba-primary-0: rgba( 35,134,158,1);	// Main Primary color */
$rgba-primary-1: rgba( 75,137,151,1);
$rgba-primary-2: rgba( 73,160,180,1);
$rgba-primary-3: rgba(  5,110,134,1);
$rgba-primary-4: rgba(  3,150,185,1);


$highlight-color: #FF8A32;
$background-color: $color-primary-3;
$title-color-inverse: #e6dff9;

$title-color: $color-primary-3;

$error-color: #bd4a33;

$normal-font-family: 'Roboto','Arial';
$title-font-family: 'Baloo 2','Verdana';

