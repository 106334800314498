@import 'assets/variables';

.app {
    text-align: center;
}

.logo {
    pointer-events: none;
    padding-right: 20px;
}

.header {
    left: 0;
    right: 0;
    background-color: $background-color;
    height: 100px;
    font-family: $title-font-family;
    font-size: calc(22px + 2vmin);
    color: white;
}

.footer {
    left: 0;
    right: 0;
    margin: 10px 0 0 0;
    padding: 20px 0;
    color: gray;
    background-color: lightgrey;
    text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $title-font-family;
    color: $title-color;
    font-weight: 300 !important;
}

.header_company_name {
    padding: 20px 10px;
    a {
        color: white !important;
        font-size: 3rem;
    }
}

.header_menu {
    li {
        color: white;
        a {
            color: white;
        }
    }

    ul {
        background-color: $background-color;
    }
}

.no_underline {
    text-decoration: none;
}

/* Mobile fixes */
@media screen and (max-width: 600px) {
    h2 {
        font-size: 2.1rem !important;
    }

    h3 {
        font-size: 1.8rem !important;
    }

    h5 {
        font-size: 1.2rem !important;
    }

    a {
        font-size: 1.1rem !important;
        BuiButton-label {
            font-size: 1.1rem !important;
        }
    }

    .header_company_name {
        padding: 30px 10px;
        a {
            color: white !important;
            font-size: 2rem !important;
        }
    }
}

.contentImage {
    width: 100%;
    border-radius: 3px;
    // box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
    //     rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}

.yrittajatLogo {
    width: 150px;
    height: 75px;
}

.yrittajatLogoContainer {
    position: absolute;
    margin-top: 70px;
    margin-left: -50px;
}

.pseudoRelativeDiv {
    position: relative;
    width: 0;
    height: 0;
}

// SP Mobile Auth Policy View BEGIN
$sp-footer-height: 40px;
$sp-theme-main-color: #674ea7;
$sp-background-color: $sp-theme-main-color;
$sp-title-font-family: 'Baloo 2', 'Verdana';

.sp-logo {
    height: 70px;
    width: 70px;
    pointer-events: none;
    padding-right: 8px;
}

.sp-container {
    min-height: 100vh;
    position: relative;
}

.sp-app-content-wrapper {
    padding-bottom: $sp-footer-height + 44px;
}

.sp-app-content-container {
    min-height: 60vh;
    padding-bottom: 48px;
}

.sp-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $sp-footer-height;
    color: gray;
    background-color: lightgrey;
    text-align: center;
    padding: 10px;
}

.sp-header {
    background-color: $sp-background-color;
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: $sp-title-font-family;
    font-size: calc(22px + 2vmin);
    color: white;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

// SP Mobile Auth Policy View END
